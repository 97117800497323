import React from "react"
import {Link} from 'gatsby'
import { inject, observer } from "mobx-react"
import addToMailchimp from "gatsby-plugin-mailchimp"


import styles from "../components/styles"
import colors from "../components/colors"


class Newsletter extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            email: "",
          }
    }


    handleGeneralAudienceSubscription = async event => {
        event.preventDefault()
        try {
          let res = await addToMailchimp(this.state.email)
    
          if (res.result === "success") {
            alert(res.msg)
          } else {
            alert(res.msg)
          }
        } catch (err) {
          console.log("error")
          console.log(err)
        }
      }
      handleBvbAudienceSubscription = async event => {
        event.preventDefault()
        try {
          let res = await addToMailchimp(this.state.email, 'https://team.us4.list-manage.com/subscribe/post?u=c03aba11e773bb6330d09bef1&amp;id=107afb2a62')
    
          if (res.result === "success") {
            alert(res.msg)
          } else {
            alert(res.msg)
          }
        } catch (err) {
          console.log("error")
          console.log(err)
        }
      }

    render() {
    let selectedStyles =
       this.props.store.device === "mobile" ? styles.mobile : styles.desktop
    let containerStyle = this.props.background ? {... selectedStyles.index.newsletterSection.container, backgroundColor:this.props.background}: selectedStyles.index.newsletterSection.container
    containerStyle = this.props.news ? {... containerStyle, padding: '7vh 0vh'} : containerStyle
    let inputStyle = this.props.background === colors.white ? {...selectedStyles.index.newsletterSection.input, border:'1px solid', borderColor: colors.darkgrey ,borderRadius:1} : selectedStyles.index.newsletterSection.input
    inputStyle = this.props.background === colors.white && this.props.store.device === 'desktop' ? {...inputStyle, margin:'4px'}:inputStyle
    
    return(
        <div style={containerStyle}>
            <div style={{ maxWidth: this.props.news? '80%' : "1080px" }}>
              <div
                style={selectedStyles.index.newsletterSection.innerContainer}
              >
                <div
                  style={selectedStyles.index.newsletterSection.textContainer}
                >
                  <h1 style={selectedStyles.index.newsletterSection.heading}>
                    {this.props.store.language === "en"
                      ? "Stay in the loop."
                      : "Bleiben Sie am Ball."}
                  </h1>
                  <p style={selectedStyles.index.newsletterSection.text}>
                    {this.props.store.language === "en"
                      ? "Sign up for our newsletter to get the latest updates."
                      : "Hier können Sie sich zu unserem Newsletter anmelden."}
                  </p>
                </div>
                <input
                  label="email address"
                  style={inputStyle}
                  type="text"
                  value={this.state.email}
                  onChange={event =>
                    this.setState({ email: event.target.value })
                  }
                  placeholder={
                    this.props.store.language === "en"
                      ? "Email Address"
                      : "Email Adresse"
                  }
                />

                <button
                  onClick={this.props.audience && this.props.audience === 'bvb'? this.handleBvbAudienceSubscription:this.handleGeneralAudienceSubscription}
                  style={selectedStyles.index.newsletterSection.button}
                >
                  {" "}
                  Sign Up{" "}
                </button>
              </div>
              <div
                style={selectedStyles.index.newsletterSection.gdprTextContainer}
              >
                {this.props.store.language === "en" ? (
                  <p style={{paddingBottom:'20px', fontSize:'1rem'}}>
                  {this.props.news?"Yes, I want to subscribe to the newsletter to receive information about the BVB-FanToken.":"Yes, I want to subscribe to the newsletter to receive information about blockchain & sport and Liquiditeam's services."}
                  </p>
                ) : (
                  <p style={{paddingBottom:'20px', fontSize:'1rem'}}>
                    {this.props.news?'Ja, ich will den Newsletter mit Informationen zum BVB-FanToken von Liquiditeam abonnieren.':'Ja, ich will den Newsletter mit Informationen zu Blockchain & Sport und dem Angebot von Liquiditeam abonnieren.'}
                  </p>
                )}

                {this.props.store.language === "en" ? (
                  <p style={{paddingBottom:this.props.store.device==='mobile'?'50px':'0px', fontSize:'1rem'}}>
                    More information regarding analytics, recordings of your
                    sign-up, the use of Mailchimp as an email service provider
                    as well as your right of withdrawal, all of which is covered
                    by your consent, can be found in the{" "}
                    <Link style={{color:colors.textDarkgrey}} to="/privacy">data privacy statement</Link>
                  </p>
                ) : (
                  <p style={{paddingBottom:this.props.store.device==='mobile'?'50px':'0px', fontSize:'1rem'}}>
                    Hinweise zur von der Einwilligung umfassten Erfolgsmessung,
                    dem Protokollieren ihrer Anmeldung, dem Einsatz des
                    Versanddienstleisters Mailchimp sowie zu ihren
                    Widerrufsrechten finden Sie in der{" "}
                    <Link style={{color:colors.textDarkgrey}} to="/privacy">Datenschutzerklärung</Link>
                  </p>
                )}
              </div>
            </div>
          </div>
    )
    }
}
export default inject("store")(observer(Newsletter))
