import React from "react"
import { inject, observer } from "mobx-react"
import settings from "../../content/settings.json"

import colors from "./colors"
import styles from "../components/styles"
import landingPageData_de from "../../content/de_landingPage.json"
import landingPageData_en from "../../content/en_landingPage.json"


const ContactBanner = props =>{
    let selectedStyles =
       props.store.device === "mobile" ? styles.mobile : styles.desktop
    let landingPageData =
      props.store.language === "en"
        ? landingPageData_en
        : landingPageData_de
    return(
      <div style={{height:'30px', backgroundColor:'black', width:'100%'}}/>
    )}
    

export default inject("store")(observer(ContactBanner))